import type { FC } from 'react';
import type { Slice } from '../../domain';
import type { SliceProps } from './SliceProps';
import { useMemo } from 'react';
import { css, styled } from '@mui/material/styles';
import { Slice_CrewProfile } from './Slice_CrewProfile';
import { Slice_Faq } from './Slice_Faq';
import { Slice_Images } from './Slice_Images';
import { Slice_InquiryForm, Slice_InquiryFormLite } from './Slice_InquiryForm';
import { Slice_MediaCarousel } from './Slice_MediaCarousel';
import { Slice_TemplatedFaq } from './Slice_TemplatedFaq';
import { Slice_Text } from './Slice_Text';
import { Slice_YachtFeatures } from './Slice_YachtFeatures';
import { Slice_YachtLayout } from './Slice_YachtLayout';
import { Slice_YachtLegal } from './Slice_YachtLegal';
import { Slice_YachtRates } from './Slice_YachtRates';
import { Slice_YachtSearch } from './Slice_YachtSearch';
import { Slice_YachtSpecification } from './Slice_YachtSpecification';
import { Slice_YachtTitle } from './Slice_YachtTitle';
import { Slice_YachtsByType } from './Slice_YachtsByType';
import { Slice_Articles } from './articles';


const SlicesRoot = styled('article')(({ theme }) => css`
	display: grid;
	grid-auto-flow: row;
	grid-template-columns: 1fr;

	> * + * {
		margin-top: ${theme.spacing(4)};
	}

	> * {
		width: 100%;
	}

	&.slices--main {
		> * {
			max-width: ${theme.contentWidth};
			margin-left: auto;
			margin-right: auto;
		}
	}
`);

/** Simple slice Components which require no special properties. */
// TODO: remove `Partial` from the definition once all slices are accounted for.
const SLICE_COMPONENTS: Readonly<Partial<Record<Slice['contentType'], FC<SliceProps<any>>>>> = { // eslint-disable-line @typescript-eslint/no-explicit-any
	ARTICLES: Slice_Articles,
	CREW_PROFILE: Slice_CrewProfile,
	FAQS: Slice_Faq,
	TEMPLATED_FAQS: Slice_TemplatedFaq,
	IMAGES: Slice_Images,
	MEDIA_CAROUSEL: Slice_MediaCarousel,
	YACHTS_BY_TYPE: Slice_YachtsByType,
	YACHT_TITLE: Slice_YachtTitle,
	YACHT_FEATURES: Slice_YachtFeatures,
	YACHT_LAYOUT: Slice_YachtLayout,
	YACHT_SPECIFICATION: Slice_YachtSpecification,
	YACHT_LEGAL: Slice_YachtLegal,
	INQUIRY_FORM: Slice_InquiryForm,
	INQUIRY_FORM_LITE: Slice_InquiryFormLite,
	YACHT_SEARCH: Slice_YachtSearch,
	YACHT_RATES: Slice_YachtRates,

	TEXT: () => { throw new Error('TEXT slice should have been handled.'); },
};

export type SlicePosition = 'head' | 'main' | 'side' | 'tail';
export type SlicesProps = {
	slices: Slice[]
	/** Disabled decoration of the first letter of the first paragraph. */
	noInitialLetter?: boolean;
	position: SlicePosition;
};
export const Slices = ({ slices, noInitialLetter, position }: SlicesProps) => useMemo(() => (
	<SlicesRoot className={`slices slices--${position}`}>
		{ slices.map((slice, index) => {
			if(slice.contentType === 'TEXT') {
				return (
					<Slice_Text
						slice={slice}
						first={index === 0 && !noInitialLetter}
						position={position}
						key={slice.id}
					/>
				);
			}

			const SliceComponent = SLICE_COMPONENTS[slice.contentType];
			if(!SliceComponent) {
				return null;
			}

			return (
				<SliceComponent slice={slice} position={position} key={slice.id}/>
			);
		})}
	</SlicesRoot>
), [noInitialLetter, position, slices]);
