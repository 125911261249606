import type { YachtLimited } from '../../../domain';
import type { ClassNameProp } from '../../util';
import { CharterIcon } from '@charterindex/icons';
import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { toFeet } from '../../../util';
import { InternalLink, TextOverImage } from '../../components';
import { CardButton } from '../../components/CardButton';
import { usePreferences } from '../../preferences';
import { PLACEHOLDER_SVG } from '../../static';
import { getImageUrl } from '../../util';
import { YachtPremiumListing } from './YachtPremiumListing';


const YachtCardButton = styled(CardButton)(({ theme }) => css`
	display: grid;
	grid-template-columns: 1fr;

	overflow: hidden;

	background-color: ${theme.palette.common.white};
	
	&:hover {
		background-color: ${theme.palette.grey[100]};
	}

	.yacht-card-header {
		position: relative;
		height: 0;
		width: 100%;
		padding-bottom: 60%;
		overflow: hidden;
		
		&__image {
			background: url('${PLACEHOLDER_SVG}');
			background-position: center;
			background-size: cover;
			
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;

			transform: scale(var(--card-button-focus-scaling, 1));
			transition: transform var(--card-button-focus-duration, 0);
		}

		&__name {
			position: absolute;
			bottom: ${theme.spacing(1)};
			left: ${theme.spacing(1)};
			font-size: 1.74rem;
			font-weight: bold;
			line-height: 1;
		}

		&__featured {
			position: absolute;
			top: ${theme.spacing(1)};
			left: ${theme.spacing(1)};
		}

		&__video {
			position: absolute;
			width: ${theme.typography.h2.fontSize};
			top: ${theme.spacing(1)};
			right: ${theme.spacing(1)};
			color: white;
		}
	}

	.yacht-card-info {
		display: flex;
		flex-direction: column;
		
		&__row {
			display: grid;
			gap: ${theme.spacing(1)};

			&--grow-left {
				grid-template-columns: 1fr auto;
			}

			&--grow-right {
				grid-template-columns: auto 1fr;
			}
		}

		&__guests {
			display: flex;
			justify-content: flex-end;
			align-items: center;

			svg {
				margin-right: .1em;
				width: .9em;
			}
		}
		
		&__price {
			margin-left: auto;
		}
	}

	.yacht-card-content {
		padding: ${theme.spacing(1)};
	}

	.yacht-card-builder {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`);


export type YachtCardProps = ClassNameProp & {
	yacht: YachtLimited;
	lazy?: boolean;
	preservePlaceSearchParam?: boolean;
};
export const YachtCard = ({ className, yacht, lazy, preservePlaceSearchParam }: YachtCardProps) => {
	const router = useRouter();
	const { t } = useTranslation();
	const { formatCurrency } = usePreferences();


	const query = useMemo(() => {
		if(!preservePlaceSearchParam) {
			return undefined;
		}

		const place = router.query.place;

		if(typeof(place) !== 'string') {
			return undefined;
		}

		return { place };
	}, [preservePlaceSearchParam, router.query.place]);

	return (
		<YachtCardButton
			className={className}
			href={yacht.uri}
			LinkComponent={p => <InternalLink {...p} query={query}/>}
		>
			<div className="yacht-card-header">
				<img
					className="yacht-card-header__image"
					src={getImageUrl(yacht.image, 600)}
					loading={lazy ? 'lazy' : 'eager'}
					alt={yacht.name}
					aria-hidden
					key={yacht.image}
				/>

				<YachtPremiumListing className="yacht-card-header__featured" yacht={yacht}/>

				{ yacht.video && <CharterIcon className="yacht-card-header__video" iconName="video" size={20}/> }

				<TextOverImage
					className="yacht-card-header__name"
					component="span"
				>
					{yacht.name}
				</TextOverImage>
			</div>
			<div className="yacht-card-content yacht-card-info">
				<div className="yacht-card-info__row yacht-card-info__row--grow-left">
					<Typography className="yacht-card-builder" variant="caption" color="textSecondary">
						{yacht.yearLaunch}&nbsp;{yacht.builder.name}
					</Typography>

					<Typography variant="caption" color="textSecondary" textAlign="right">
						{toFeet(yacht.lengthMeters)}ft&nbsp;/&nbsp;{yacht.lengthMeters}m
					</Typography>
				</div>

				<div className="yacht-card-info__row yacht-card-info__row--grow-right">
					<div className="yacht-card-info__guests" title={t('common:guests')}>
						<CharterIcon iconName="guests" size={20}/>
						<Typography component="span">
							{yacht.guestsSleeping}
						</Typography>
					</div>
					<div className="yacht-card-info__price" key={yacht.lowPrice}>
						<Typography component="span" fontWeight="bold">
							{formatCurrency(yacht.lowPrice)}
						</Typography>
						<Typography component="span" variant="caption">
								&nbsp;{t('common:per-week-short')}
						</Typography>
					</div>
				</div>
			</div>
		</YachtCardButton>
	);
};
